import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

function FAQ() {
  return (
    <Layout>
      <SEO title="FAQ" />
      <main className="text-purple-600 container py-4">
        <h1 className="text-lg lg:text-2xl text-center mb-6">FAQs</h1>

        <div className="bg-gray-300 p-12">
          <h2 className="font-bold text-lg lg:text-2xl">
            How long does it take?
          </h2>
          <p className="mt-4">
            Our normal production time is 5 to 10 business days depending on the
            selection of materials. But, if you need the project done in an hour
            and we have the man power and materials available, we will make our
            best effort to make your deadline. Handcrafted and gilded frames
            require 4-6 weeks for delivery.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            How much does it cost?
          </h2>
          <p className="mt-4">
            The cost of the frame has many variables. Frame selection, type of
            glass and size predicate the price. Because of our wide variety, we
            are positioned to fit anyone’s budget and style.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            What type of glass should I use?
          </h2>
          <p className="mt-4">
            A variety of glass and acrylic selections are now available. Regular
            glass and Plexiglass are the most cost effective. Museum Glass and
            Optium Plexi provide the ultimate UV protection and crystal clarity
            – almost invisible! Contact one of our designers to discuss the
            perfect selection for your artwork.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            What is the appropriate mat size?
          </h2>
          <p className="mt-4">
            There is no right or wrong rule for the selection of a mat size. But
            there are a few guidelines that can be followed:
          </p>
          <ul className="mt-4 list-disc">
            <li className="ml-6">
              If the art is small, a 1-3”mat is appropriate
            </li>
            <li className="ml-6">
              If the art is medium,a 2-4”mat is appropriate
            </li>
            <li className="ml-6">
              If the art is large, a 4-6” mat is appropriate
            </li>
          </ul>
          <p className="mt-4">
            ***Please note that the mat size is also determined by the frame
            size. Usually the two are not the same in width or the design may
            appear banded.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            How can I preserve my artwork?
          </h2>
          <p className="mt-4">
            Always use 100% rag mats, archival backing and glazing with UV
            protection. Everything within the chamber of the frame must be
            acid-free.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            How to hang a picture?
          </h2>
          <p className="mt-4">
            If hanging with wire, find your placement and mark the top of the
            frame on the wall with a pencil. (If you don’t want to put pencil
            marks on your wall, you can use a low adhesive tape. Place the tape
            on the wall and make your mark.) From the back of the picture frame,
            measure from the top down to the wire. Place your hook at this
            position on the wall. Hooks come in different sizes in proportion to
            the weight – 30lbs, 50lbs, 75lbs, 100lbs.
          </p>
          <p className="mt-4">
            Hooks are now made to support the weight of picture frames without
            finding studs in the wall.
          </p>
          <p className="mt-4">
            If you want your frames to be flush against the wall, use mirror
            hooks (D rings on each side of the frame). Find your position on the
            wall. Mark the wall behind the left D ring and find the distance
            from your mark to the ceiling. Call this mark X. Measure between the
            center of the two D rings and call this measurement Y. From your
            original mark on the wall, measure to the right and make a mark at Y
            distance. From there, measure up to the ceiling and find the point
            where it equals the X measurement – this could be up or down. Put
            one hook into the wall on your left mark and one hook into the wall
            on your right mark. Measuring will take longer but it’s worth it.
            The picture will not move if bumped.
          </p>
          <p className="mt-4">
            You can also use Z-BAR. This is a metal rail that’s put on the top
            rear of the frame. It hooks into another piece that is attached to
            the wall. Mirror hooks and Z-Bar are good to use with heavier
            pieces.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            How to size a mirror?
          </h2>
          <p className="mt-4">
            When trying to size a mirror, start with a large piece of paper.
            Craft paper works well. Cut the paper to the approximate outside
            dimension you think you want and hang it on the wall with low
            adhesive tape. Set back and envision your mirror. If too big, cut
            some of the paper off. Let us know what the outsize dimension is and
            what type of mirror you want – plain, beveled, antiqued. Pick out
            your frame and we can do the rest. You may check out frame samples
            on approval to look at in your home for 48 hours.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            How to arrange a photo wall?
          </h2>
          <p className="mt-4">
            How long is your space? How many pictures do you want to hang? Are
            you planning on adding to the wall in the future? We can help you
            determine the best answers to these questions.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            How to earthquake proof your artwork?
          </h2>
          <p className="mt-4">
            Security hangers are one of the best ways to earthquake proof your
            artwork. The wall would have to come down before the artwork would
            fall. Also, think about using Plexiglass instead of glass. Plexiglass
            will crack, but glass will shatter.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            Can you provide environmentally friendly framing?
          </h2>
          <p className="mt-4">
            We have established a partnership with several vendors that promote
            forest-friendly products. They provide wood from sustainable
            forests that are eco-conscious. We have the ability to supply raw
            wood frames to clients who want to create their own finishes.
          </p>

          <h2 className="font-bold text-lg lg:text-2xl mt-12">
            How long will UV plexiglass maintain its filtering properties?
          </h2>
          <p className="mt-4">
            UV protection should last 10-20 years. But, just as you need to
            re-apply sunscreen when in the sun, UV protection on your art will
            reach a maximum if exposed to intense light for long periods of
            time. Fluorescent and iridescent light can also harm artwork. We
            suggest that you keep all artwork out of direct sunlight. If you
            think that your artwork may be fading, bring it in to L. A. Art
            Exchange for a free conservation analysis.
          </p>
        </div>
      </main>
    </Layout>
  )
}

export default FAQ
